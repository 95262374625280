.sk_line {
    background: #d9d9d9;
    border-radius: 30px;
    height: 12px;
    width: 100%;
    margin: 10px 0;
    overflow: hidden;
    position: relative;
}
.sk_line.sk_line_10{
    width: 10%;
}
.sk_line.sk_line_20{
    width: 20%;
}
.sk_line.sk_line_30{
    width: 30%;
}
.sk_line.sk_line_40{
    width: 40%;
}
.sk_line.sk_line_50{
    width: 50%;
}
.sk_line.sk_line_60{
    width: 60%;
}
.sk_line.sk_line_70{
    width: 70%;
}
.sk_line.sk_line_80{
    width: 80%;
}
.sk_line.sk_line_90{
    width: 90%;
}
.sk_line.sk_line_100{
    width: 100%;
}

.sk_line.sk_line_height_8{
    height: 8px;
}
.sk_line.sk_line_height_10{
    height: 10px;
}
.sk_line.sk_line_height_12{
    height: 12px;
}
.sk_line.sk_line_height_14{
    height: 14px;
}
.sk_line.sk_line_height_16{
    height: 16px;
}
.sk_line.sk_line_height_18{
    height: 18px;
}
.sk_line.sk_line_height_20{
    height: 20px;
}
.sk_line.sk_line_height_22{
    height: 22px;
}
.sk_line.sk_line_height_24{
    height: 24px;
}
.sk_line.sk_line_height_26{
    height: 26px;
}
.sk_line.sk_line_height_28{
    height: 28px;
}
.sk_line.sk_line_height_30{
    height: 30px;
}
.sk_line.sk_line_height_32{
    height: 32px;
}
.sk_line.sk_line_height_34{
    height: 34px;
}
.sk_line.sk_line_height_36{
    height: 36px;
}
.sk_line.sk_line_height_38{
    height: 38px;
}
.sk_line.sk_line_height_42{
    height: 42px;
}

.sk_rect{
    height: 45px;
    width: 100%;
    background: #d9d9d9;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    background-color: #d9d9d9 !important;
}

.sk_img{
    height: 75px;
    width: 75px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    background-color: #d9d9d9 !important;
}

.sk_line:before, .sk_img:before, .sk_rect::before, .sk_img_thumb:before, .sk_img_square_product:after{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left:0;
    background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
    background-repeat: no-repeat;
    background-size: 450px 400px;
    animation: shimmer 1s linear infinite;
}



.sk_img:before {
    background-size: 650px 600px;
}

@keyframes shimmer {
    0%{
      background-position: -450px 0;
    }
    100%{
      background-position: 450px 0;
    }
}

.sk_img_square{
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    -webkit-aspect-ratio: 1/1;
    -moz-aspect-ratio: 1/1;
    -o-aspect-ratio: 1/1;
    -ms-aspect-ratio: 1/1;
    border-radius: 0;
}

.sk_img_square_thumb{
    aspect-ratio: 1/1;
    -webkit-aspect-ratio: 1/1;
    -moz-aspect-ratio: 1/1;
    -o-aspect-ratio: 1/1;
    -ms-aspect-ratio: 1/1;
    border-radius: 0;
    height: 85px;
    width: 85px;;
}

.sk_img_autoHeight{
    width: 100%;
    height: auto;
    border-radius: 0;
}

.sk_img_author_image{
    width: 43px;
    height: 43px;
    border-radius: 0;
}

.sk_img_square_product{

    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    background-color: #d9d9d9 !important;
    
    width: 100%;
    aspect-ratio: 1/1;
    -webkit-aspect-ratio: 1/1;
    -moz-aspect-ratio: 1/1;
    -o-aspect-ratio: 1/1;
    -ms-aspect-ratio: 1/1;
    border-radius: 0;
    position: relative;
}

.sk_img.sk_img_autoHeight:before {
    background-size: 650px 100%;
}

