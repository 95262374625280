
.sk_loader_product, .sk_loader_product_thumb {
    width: 100%;
    height: 100%;
    position: relative;
    background: #d9d9d9;
    background-color: #d9d9d9!important;
    overflow: hidden;
}

.sk_loader_product:before, .sk_loader_product_thumb:before{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left:0;
    background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
    background-repeat: no-repeat;
    background-size: 450px 400px;
    animation: shimmer 0.8s linear infinite;
}

.sk_loader_product:before{
    background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0, 0, 0, 0.05) 30%, #d9d9d9 60%, #d9d9d9 100%);
}

.sk_loader_product_thumb:before{
    background-size: 650px 600px;
}

.sk_loader_product:before {
    background-size: 100% 100%;
}
