:root{
    --primary-color: #fff;
    --secoundry-color: #121212;
    --text-color: #6D7175;
    --border-color: #DBE1E5;
    --primary-btn:#5454FC;
    --action-btns: #DBE1E5;
    --input-bg: #ffffff;
    --input-icon: #6D7175;
    --m-input-bg: #DBE1E5;
    --options-bg: #FFFFFF;
    --options-hover: #f5f5f5;
    --pleceholder:#000;
    --search-bg:#fff;
    --search-input-bg:#dbe1e5;
    --dark-text-color: #000;
}

.dark-theme{
    --primary-color: #121212;;
    --secoundry-color: #FFF;
    --text-color: #F5F5F5;
    --border-color: #585858;
    --primary-btn:#5454FC;
    --action-btns: #ffffff;
    --input-bg: #000000;
    --input-icon: #ffffff;
    --m-input-bg: #000000;
    --options-hover: #3A3B3C;
    --pleceholder:#ffffff;
    --options-bg: #242526;
    --search-bg:#000;
    --search-input-bg:#3A3B3C;
    --dark-text-color: #fff;
}

body{ 
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

    -webkit-font-smoothing: subpixel-antialiased
}

::-webkit-scrollbar {
    width: 3px;
    border-radius: 36px;
  }
   
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.9);
    border-radius: 36px;
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.6);
    border-radius: 36px;
  }


div#shopOnRoot a {
    text-decoration: none;
}

.lbUser_embed{	
    background: var(--primary-color);	
    padding-top: 20px;	
    max-width: 680px;	
    margin: 0 auto;	
    min-height: 100vh;	
}

.lbUser_header {
    margin-bottom: 15px;
}

.lbUserheader_top {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;
    padding: 0 20px 20px;
}

.lbUser_profile {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.lb_backbutton {
    background: transparent;
    padding: 0;
    border: none;
    margin-right: 5px;
    cursor: pointer;
}

.lb_backbutton svg path {
    fill: var(--secoundry-color);
}

.lbUser_profileouter {		
    border-radius: 100%;		
    width: 38px;		
    height: 38px;		
    overflow: hidden;		
    margin-right: 10px;		
    background: #e4e6ee;		
    display: flex;		
    align-items: center;		
    justify-content: center;		
}

.lbUser_profileouter img {	
    width: 100%;	
    height: 38px;	
    width: 38px;	
}

.lbUser_profileImage{
    width: 100%;
    height: 38px;		
    width: 38px;
}

.lb_title {	
    font-size: 15px;	
    margin-bottom: 0;	
    color: var(--secoundry-color);	
    width: calc(100% - 38px);	
    word-break: break-all;	
    font-weight: normal;
}

.lbUser_descrioption {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: all 1s;
}

.lbReadmore {
    float: right;
    font-size: 13px;
    font-weight: 400;
    color: #5454FC;
    line-height: 20px;
    background: var(--primary-color);
    padding: 0 10px;
    border: none;
    position: absolute;
    right: 0;
    bottom: -2px;
    cursor: pointer;
}

.lbUser_actions {
    padding: 0 20px;
}

.lbUser_actions_inner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lbBrowse_product { 
    margin-right: 5px;
}

.lbSearch_product { 
    margin-left: 5px;
}

.lbBrowsebtn{
    width: 100%;
}

.lbBrowsebtn, .lbSearch_product {
    border: none;
    padding: 0 0 0 13px;
    height: 38px;
    background: var(--action-btns);
    font-size: 12px;
    font-weight: 500;
    color: #202223;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
}

.lbBrowse_product, .lbSearch_product {
    width: 50%;
    position: relative;
}

.lbPosts_Products {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    padding: 0 15px;
}

.lb_image_holder {
    width: 33.333%;
    padding: 5px;
    cursor: pointer;
}

.lb_PPimage {
    aspect-ratio: 1/1;
    -webkit-aspect-ratio: 1/1;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    position: relative;
}

.lbbrowse_dropdown {
    position: absolute;
    width: 100%;
    background: var(--options-bg);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    z-index: 9;
}

.lbbrowse_dropdown.active{
    height: auto;
    visibility: visible;
    opacity: 1;
}

.lbdrop_heading {
    display: none;
}

.lbbrowse_option {
    width: 100%;
    background: var(--options-bg);
    padding: 10px 15px;
    cursor:pointer;
    color: var(--text-color)
}

.lbbrowse_option:hover {
    background: var(--options-hover);
}

.lbSearch {
    position: absolute;
    width: 0;
    padding: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
}

.lbSearchbox {
    width: 0;
    border: none;
    padding: 0;
    transition: all 0.3s;
}

.lbSearchbox::-webkit-input-placeholder { color: var(--pleceholder); }
.lbSearchbox::-moz-placeholder { color: var(--pleceholder); }
.lbSearchbox:-ms-input-placeholder { color: var(--pleceholder); }
.lbSearchbox:-moz-placeholder { color: var(--pleceholder); }

.lbSearchbox:focus-visible, 
.lbSearchbox:focus, 
.lbSearchbox:hover, 
.lbSearchbox:active {
    outline: none;
    box-shadow: none;
}

.lbSearchClose {
    position: absolute;
    display: flex;
    padding: 10px 15px;
    cursor: pointer;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.lbSearchClose svg {
    width: 0;
    height: 0;
    transition: all 0.3s;
}

.lbSearch.active .lbSearchClose svg{
    width: 16px;
    height: 16px;
}

.lbSearch.active .lbSearchClose svg path {
    fill: var(--text-color)
}

.lbSearch.active {
    width: 100%;
    transition: all 0.3s;
}

.lbSearch.active .lbSearchbox {
    padding: 5px 15px;
    width: 100%;
    height: 38px;
    border: 1px solid var(--border-color);
    color: var(--secoundry-color);
    transition: all 0.3s;
}

.lbUser_descrioption.active {
    display: block;
    overflow: visible;
    transition: all 1s ease-in-out;
}

/* ==== MOBILE DEVICE CSS START ==== */

.lbUser_embed.mobile_view {
    position: relative;
    overflow: hidden;
    padding: 0 5px;
    overflow-y: scroll;
    max-height: 485px;
    margin-top: 10px;
}

.mobile_view .lbUserheader_top {
    padding: 0 0 20px;
}

.lbUser_embed.mobile_view .lbUser_actions {
    padding: 0;
}

.lbUser_embed.mobile_view .lb_image_holder{
    width:50%;
}

.lbUser_embed.mobile_view .lbPosts_Products {
    margin: 0 -5px;
}

.lbUser_descrioption.active .lbReadmore {
    visibility: hidden;
}

.lbUser_descrioption.active .lbReadmore:before {
    position: absolute;
    content: "Read Less";
    width: auto;
    opacity: 1;
    visibility: visible;
    padding: 0 0 0 10px;
    background: var(--primary-color);
}

.lbUser_embed.mobile_view .lbbrowse_dropdown.active {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    left: 0;
    padding: 20px 0;
    border-radius: 0 0 4px 4px;
    background: var(--primary-color);
}

.lbUser_embed.mobile_view .lbbrowse_dropdown {
    box-shadow: none;
}

.lbUser_embed.mobile_view .lbBrowse_overlay:after {
    position: absolute;
    content: "";
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    bottom: 122px;
    left: 0;
}

.lbUser_embed.mobile_view .lbSearch_overlay .lbSearch.active {
    position: absolute;
    top: 40px;
}

.lbUser_embed.mobile_view.lbSearch_overlay:after {
    position: absolute;
    content: "";
    background: var(--primary-color);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

.lbUser_embed.mobile_view .lbSearch.active {
    position: absolute;
    top: 40px;
    z-index: 2;
    width: 100%;
    transition: none;
    padding: 0 10px;
}

.lbUser_embed.mobile_view .lbSearchbox {
    width: 100%;
    height: 38px;
    background: var( --m-input-bg);
    color: #fff;
    border: none;
    transition: none;
    border-radius: 3px;
}

.lbUser_embed.mobile_view .lbSearchClose {
    padding: 10px;
    right: 10px;
}

.lbUser_embed.mobile_view::-webkit-scrollbar {
    display: none;
}


/*== PRODUCT DETAIL PAGE CSS START ==*/

.lbProduct_detail {
    padding: 0 15px 20px;
}

.lbProduct_detail:after {
    display: block;
    clear: both;
    content: "";
}

.lbProduct_detail .lbProduct_slider {
    width: 50%;
    float: left;
    padding: 0 15px 0px 0;
}

.lbProduct_detail .splide__pagination {
    bottom: -25px;
    display: block !important;
    text-align: center;
}

.lbPost_detail  .splide__pagination__page {
    width: 6px;
    height: 6px;
    background: #c1c1c1;
    opacity: 1;
}

.lbPost_detail_image_holder .splide__pagination--ltr {	
    bottom: -25px;
    display: block !important;	
    text-align: center;
}

.lbPost_detail .splide__pagination__page.is-active {
    background: #5454FC ;
}

.lbUserheader_top.header_only {
    padding-bottom: 0;
    border-bottom: none;
}

.lbUserheader_top.header_only + .lbUser_actions {
    display: none;
}

.lbProduct_heading {
    float: right;
    width: 50%;
    margin-top: 15px;
}

.lbProduct_title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 1.4;
    color: var(--dark-text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    
}

.lbProduct_price {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    color: var(--dark-text-color);
    width: 100%;
    
}

.lbProduct_deleted_price {
    display: flex;
    align-items: center;
    color: #b9b9b9;
    margin-left: 15px;
    font-weight: 400;
    font-size: 14px;
}

.lbTagged_post {
    margin-top: 25px;
    float: right;
    width:50%;
}

.lbTagged_post_wrapper {
    overflow-y: auto;
    max-height: 210px;
    margin-bottom: 10px;
}

.lbTagged_post_box {
    margin-bottom: 20px;
    display: flex;
}

.lbTagged_image_outer {
    margin-right: 19px;
}

.lbTagged_post_image {
    width: 85px;
    height: 85px;
    object-fit: cover;
    position: relative;
}

.lbTagged_post_title {
    line-height: normal;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}

.lbTagged_content {
    /* padding-right: 3px; */
    padding-right: 0;
    width: calc(100% - 104px);
    
}

.lbTagged_post_desc {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-color);
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transition: all 1s;
}

.lbTagged_post_desc.active {
    display: block;
    overflow: visible;
    transition: all 1s ease-in-out;
}

.lbTagged_post_desc.active .lbReadmore:before {
    position: absolute;
    content: "Read Less";
    width: auto;
    opacity: 1;
    visibility: visible;
    padding: 0 0 0 10px;
    background: var(--primary-color);
}

.lbTagged_post_desc.active .lbReadmore{
    visibility: hidden;
}

.lbTagged_view_website a {
    background: #4E69ED;
    color: #fff;
    text-align: center;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #4E69ED;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
    transition: 0.2s ease-in-out;
}

.lbTagged_view_website:hover a {
    background: none;
    color: #4E69ED;
}

.lbProduct_detail .lb_products {
    padding: 0;
    width: 100%;
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid var(--border-color);
    clear: both;
}

.lbProduct_detail_inner:after {
    display: block;
    clear: both;
    content: "";
}

.lbProduct_detail .lb_products .lbproduct_thumb {
    width: 33.333%;
    padding: 5px;
}

/*== PRODUCT DETAIL PAGE CSS END ==*/

/*PRODUCT PAGE CSS START*/

.lb_products{
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    padding: 0 10px;
}

.lbproduct_thumb {
    width: 33.333%;
    padding: 5px;
    cursor: pointer;
}

.lbproduct_image_holder {
    aspect-ratio: 1/1;
    -webkit-aspect-ratio: 1/1;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    position: relative;
    transition: 0.3s ease-in-out;
}

.lbproduct_image_holder:before {
    position: absolute;
    content: "";
    background: rgba(0,0,0);
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    opacity: 0;
    transition: 0.3s ease-in-out;
}

.lbproduct_image_holder:hover:before {
    opacity: 0.3;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
}

.lbproduct_thumb_image{
    aspect-ratio: 1/1;
    -webkit-aspect-ratio: 1/1;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 9;
}

.lbproduct_heading{
    font-size: 13px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 5px;
    line-height: 15.73px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.lbproduct_price {	
    font-size: 13px;	
    color: #6D7175;	
    display: flex;	
    align-items: baseline;	
    line-height: 15.73px;	
    overflow: hidden;	
    text-overflow: ellipsis;	
    word-break: break-all;	
    white-space: nowrap;
}


/*PRODUCT PAGE CSS END*/

/*POST DETAIL PAGE CSS START*/

.lbPost_detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
				
.tb_video_wrap video {		
    width: 100%;	
    vertical-align: top;	
}

.lbPost_detail_image_holder {
    width: 50%;
    padding: 0 15px;
    /* display: flex; */
    /* align-items: center; */
}

.lbPost_detail_inner {
    display: flex;
    margin-top: 30px;
    width: 100%;
}

.lbProduct_detail_inner{
     margin-top: 30px;
}

.lbPost_detail_image {
    width: 100%;
}

.lbPost_detail_author_detail {	
    width: 50%;	
    padding: 0 15px 20px;	
    max-height: 400px;	
    overflow-y: auto;	
}

.lbPost_detail_author_inner {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.lbPost_detail_author_image_holder {
    margin-right: 15px;
    width: 43px;
    height: 43px;
}

.lbPost_detail_author_image{
    width: 100%;
    display: block;
    opacity: 1;
    max-width: 100%;
    visibility: visible;
    height: 100%;
    margin: 0 auto;
    object-fit: cover;
    position: relative;
}

.lbPost_detail_alignRight {
    width: calc(100% - 53px);
}

.lbPost_detail_author_name {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: var(--secoundry-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lbPost_detail_post_time {
    font-size: 13px;
    color: var(--text-color);
    font-weight: 400;
}

.lbpost_detail_product_box {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.lbpost_detail_product_image_holder {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    aspect-ratio: 1/1;
}

.lbpost_detail_product_image {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
}

.lbpost_detail_product_title {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 5px;
    color:var(--text-color);
}

.lbpost_detail_product_price {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: var(--text-color);
    margin-bottom: 15px;
}

.lbpost_detail_product_button {
    text-align: center;
    color: #fff;
    background: #4E69ED;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 26px;
    cursor: pointer;
}

.lb_posts{
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}

.lbPost_detail .lb_posts {
    padding: 0;
    width: 100%;
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid var(--border-color);
    clear: both;
}


.tcc_section .nav {
    justify-content: center;
    padding: 0;
    border-bottom: 1px solid #e2edf5;
}

.shopOnBio_section li.nav-item {	
    width: 50%; 	
}

.lb_image_holder_bg{
    background: rgb(184, 184, 184);
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    position: relative;
}

.lbpost_detail_content{	
    color: var(--text-color);	
    word-break: break-all;
    font-size: 13px;
}

.lb_loadmore{ 
    color: #fff; 
    text-align: center;
}

.tcc_section .nav.nav-pills .nav-item {
    width: 50%;
}

.tcc_section .nav .nav-item .nav-link {
    background: none;
    font-size: 14px;
    font-weight: 600;
    color: #6D7175 !important;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 10px 15px;
    transition: all 0.4s;
    width: 100%;
}

.tcc_section .nav .nav-item .nav-link.active, .tcc_section .nav .nav-item .nav-link:hover {
    border-color: #080808;
    color: #080808 !important;
}

.tb_modal_img_holder_001 .tb-detail-image-iframe, .tb_modal_img_holder_001 .tb-detail-image-iframe iframe {
    width: 100% !important;
    height: 100% !important;
    min-width: 0 !important;
}

.tb_media_holder, .tb_img_holder{
    width:100%;
    position: relative;
}

.lbPost_detail_image{
    position: relative;
}

/*POST DETAIL PAGE CSS END*/


/* ==== MOBILE DEVICE CSS END ==== */

/* DARK MODE THEME CSS START */

.preview_screen.dark-theme {
    background: #121212;
}

.dark-theme .tcc_section .nav .nav-item .nav-link.active, 	
.dark-theme .tcc_section .nav .nav-item .nav-link:hover {	
    border-color: #ffffff;	
    color: #ffffff !important;	
}

.dark-theme .lbTagged_post_title{ color: #f3f3f3; }
.dark-theme .lbproduct_heading{ color: #cecece; }
.dark-theme .lbproduct_price{ color: #cecece; }

.sb_video_carousel_icon {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px;
    z-index: 9;
}

.ts_video_icon {
    color: #fff;
}

.ts_social_icon{
    color: #fff;
}

.ts_shoppable_icon{
    color: #fff;
    position: absolute; 
    right: 5px;
    top: 5px;
    z-index: 9;

}

/* DARK MODE THEME CSS END */

.lbHomePageSkeleton .tcc_section .nav .nav-item .nav-link,
.lbHomePageSkeleton .tcc_section .nav .nav-item .nav-link.active, 
.lbHomePageSkeleton .tcc_section .nav .nav-item .nav-link:hover{
    border:none;
}


@media screen and (max-width:767px){

    .lbUserheader_top{
        padding: 0 15px 20px;
    }

    .lbUser_actions{
        padding: 0 15px;
    }

    .lbPosts_Products{
        padding: 0;
    }

    .lb_image_holder {
        width: 33.33%;
    }

    .lb_posts{
        padding: 0 10px;
    }

    /* Product Detail Page CSS */
    .lbProduct_detail .lbProduct_slider {
        width: 100%;
        float: none;
        padding: 0 0 30px;
    }

    .lbProduct_heading {
        float: none;
        width: 100%;
    }

    .lbTagged_post {
        margin-top: 25px;
        float: none;
        width: 100%;
    }

    .lbProduct_detail .lb_products .lbproduct_thumb {
        width: 33.33%;
    }

    .lbProduct_detail .lbProduct_slider {	
        width: 100%;	
        padding: 0;	
        margin-bottom: 15px;	
    }

    .lbProduct_heading, .lbTagged_post {	
        width: 100%;	
    }


    /* Post detail Page CSS */
    .lbPost_detail_inner {
        flex-wrap: wrap;
        width: 100%;
    }

    .lbPost_detail_image_holder {
        width: 100%;
    }

    .lbPost_detail_author_detail{
        width:100%;
        margin-top: 20px;
    }

    .lbPost_detail .lb_posts {
        padding: 0 10px;
    }

    .lbproduct_image_holder:before,		
    .lbproduct_image_holder:hover:before {		
        display: none;		
    }

    .lbTagged_post_wrapper {
        max-height: 100%;
        margin-bottom: 10px;
    }

}


@media screen and (max-width:575px){
    .lb_image_holder {
        width: 33.333%;
    }

    .lbbrowse_dropdown {
        position: fixed;
        width: 100%;
        left: 0;
        bottom:-100%;
        padding: 14px 0 80px;
    }

    .lbbrowse_dropdown.active {
        display: flex;
        flex-wrap: wrap;
        bottom: 0;
        height: auto;
        transition: all 0.6s;
    }

    .lbdrop_heading {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 14px;
        margin-bottom: 14px;
        border-bottom: 1px solid #DBE1E5;
    }

    .lbSearch {
        position: fixed;
        width: 100%;
        transform: none;
        bottom: -100%;
        bottom: 0;
        top: auto;
        left: 0;
    }

    .lbSearch.active {
        background: var(--search-bg);
        height: 100%;
        z-index: 9999;
        top: 55px;
        bottom: auto;
        transform: none;
    }

    .lbSearch_inner {
        position: relative;
    }

    .lbSearch_inner {
        position: relative;
        width: 90%;
        left: 50%;
        top: 40px;
        transform: translate(-50%, -50%);
    }

    .lbSearch .lbSearchbox {
        border-radius: 4px;
        width: 100%;
        background: var(--search-input-bg);
    }

    .lbSearch.active .lbSearchbox {
        height: 45px;
        border: none;
    }

    .lbProduct_detail .lb_products .lbproduct_thumb {
        width: 33.333%;
    }

}


@media screen and (max-width:360px){

    /* Post detail Page CSS */

    .lbpost_detail_product_button {
        padding: 6px 20px;
    }
}

 
