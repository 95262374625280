@font-face {
  font-family: 'TaggboxSocialFonts';
  src:  url('https://cloud.taggbox.com/taggshop-theme/web-fonts/TaggboxIcon.eot?jkob7x');
  src:  url('https://cloud.taggbox.com/taggshop-theme/web-fonts/TaggboxIcon.eot?jkob7x#iefix') format('embedded-opentype'),
    url('https://cloud.taggbox.com/taggshop-theme/web-fonts/TaggboxIcon.ttf?jkob7x') format('truetype'),
    url('https://cloud.taggbox.com/taggshop-theme/web-fonts/TaggboxIcon.woff?jkob7x') format('woff'),
    url('https://cloud.taggbox.com/taggshop-theme/web-fonts/TaggboxIcon.svg?jkob7x#TaggboxSocialFonts') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.tb__icon{
  font-family: 'TaggboxSocialFonts';
  speak-as: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tb-multi-image:before {
  content: "\e90c";
}
.tb-quote-left-alt:before {
  content: "\e909";
}
.tb-quote-right-alt:before {
  content: "\e90a";
}
.tb-reply-alt:before {
  content: "\e905";
}
.tb-share-outline:before {
  content: "\e906";
}
.tb-eye-alt:before {
  content: "\e904";
}
.tb-retweet:before {
  content: "\e907";
}
.tb-heart-alt:before {
  content: "\e908";
}
.tb-like-alt:before {
  content: "\e913";
}
.tb-comment:before {
  content: "\e914";
}
.tb-comment-dots:before {
  content: "\f4ad";
}
.tb-share-hover:before {
  content: "\e915";
}
.tb-star-alt:before {
  content: "\e90d";
}
.tb-yelp-review:before {
  content: "\e90e";
  color: rgb(244, 57, 57);
}
.tb-yelp-star:before {
  content: "\e916";
  color: rgb(255, 255, 255);
}
.tb-google-rating:before {
  content: "\e90b";
}
.tb-airbnb-rating:before {
  content: "\e90b";
}
.tb-close-alt:before {
  content: "\e90f";
}
.tb-arrow-right-alt:before {
  content: "\e911";
}
.tb-arrow-left-alt:before {
  content: "\e912";
}
.tb-image-placeholder:before {
  content: "\e917";
}
.tb-upload:before {
  content: "\e902";
}
.tb-play-fill:before {
  content: "\e918";
}
.tb-play-outline:before {
  content: "\e903";
}
.tb-Oops:before {
  content: "\e919";
}
.tb-more:before {
  content: "\e91a";
}
.tb-plug .path1:before {
  content: "\e91b";
  color: rgb(115, 117, 116);
}
.tb-plug .path2:before {
  content: "\e91c";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path3:before {
  content: "\e91d";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path4:before {
  content: "\e91e";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path5:before {
  content: "\e91f";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path6:before {
  content: "\e923";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path7:before {
  content: "\e924";
  margin-left: -4.279296875em;
  color: rgb(196, 196, 196);
}
.tb-plug .path8:before {
  content: "\e925";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path9:before {
  content: "\e926";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path10:before {
  content: "\e928";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path11:before {
  content: "\e92a";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path12:before {
  content: "\e92b";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path13:before {
  content: "\e92c";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path14:before {
  content: "\e934";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path15:before {
  content: "\e935";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path16:before {
  content: "\e936";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path17:before {
  content: "\e937";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path18:before {
  content: "\e93b";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path19:before {
  content: "\e93c";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path20:before {
  content: "\e93d";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path21:before {
  content: "\e93e";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path22:before {
  content: "\e93f";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path23:before {
  content: "\e940";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path24:before {
  content: "\e941";
  margin-left: -4.279296875em;
  color: rgb(255, 255, 255);
}
.tb-plug .path25:before {
  content: "\e942";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path26:before {
  content: "\e943";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path27:before {
  content: "\e944";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path28:before {
  content: "\e945";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path29:before {
  content: "\e946";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path30:before {
  content: "\e947";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path31:before {
  content: "\e948";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path32:before {
  content: "\e949";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path33:before {
  content: "\e94a";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path34:before {
  content: "\e94b";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path35:before {
  content: "\e94c";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path36:before {
  content: "\e94d";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-plug .path37:before {
  content: "\e94e";
  margin-left: -4.279296875em;
  color: rgb(115, 117, 116);
}
.tb-search:before {
  content: "\f002";
}
.tb-heart:before {
  content: "\f004";
}
.tb-star:before {
  content: "\f005";
}
.tb-user:before {
  content: "\f007";
}
.tb-th-large:before {
  content: "\f009";
}
.tb-th:before {
  content: "\f00a";
}
.tb-th-list:before {
  content: "\f00b";
}
.tb-check:before {
  content: "\f00c";
}
.tb-times:before {
  content: "\f00d";
}
.tb-search-plus:before {
  content: "\f00e";
}
.tb-search-minus:before {
  content: "\f010";
}
.tb-cog:before {
  content: "\f013";
}
.tb-home:before {
  content: "\f015";
}
.tb-download:before {
  content: "\f019";
}
.tb-redo:before {
  content: "\f01e";
}
.tb-sync:before {
  content: "\f021";
}
.tb-flag:before {
  content: "\f024";
}
.tb-volume-off:before {
  content: "\f026";
}
.tb-volume-down:before {
  content: "\f027";
}
.tb-volume-up:before {
  content: "\f028";
}
.tb-camera-fill:before {
  content: "\f030";
}
.tb-bold:before {
  content: "\f032";
}
.tb-video-alt:before {
  content: "\f03d";
}
.tb-edit:before {
  content: "\f044";
}
.tb-step-backward:before {
  content: "\f048";
}
.tb-fast-backward:before {
  content: "\f049";
}
.tb-backward:before {
  content: "\f04a";
}
.tb-play-alt:before {
  content: "\f04b";
}
.tb-pause:before {
  content: "\f04c";
}
.tb-stop:before {
  content: "\f04d";
}
.tb-forward:before {
  content: "\f04e";
}
.tb-fast-forward:before {
  content: "\f050";
}
.tb-step-forward:before {
  content: "\f051";
}
.tb-chevron-left:before {
  content: "\f053";
}
.tb-chevron-right:before {
  content: "\f054";
}
.tb-plus-circle:before {
  content: "\f055";
}
.tb-minus-circle:before {
  content: "\f056";
}
.tb-times-circle:before {
  content: "\f057";
}
.tb-check-circle:before {
  content: "\f058";
}
.tb-question-circle:before {
  content: "\f059";
}
.tb-info-circle:before {
  content: "\f05a";
}
.tb-arrow-left:before {
  content: "\f060";
}
.tb-arrow-right:before {
  content: "\f061";
}
.tb-arrow-up:before {
  content: "\f062";
}
.tb-arrow-down:before {
  content: "\f063";
}
.tb-share:before {
  content: "\f064";
}
.tb-expand:before {
  content: "\f065";
}
.tb-compress:before {
  content: "\f066";
}
.tb-plus:before {
  content: "\f067";
}
.tb-minus:before {
  content: "\f068";
}
.tb-exclamation-circle:before {
  content: "\f06a";
}
.tb-eye:before {
  content: "\f06e";
}
.tb-eye-slash:before {
  content: "\f070";
}
.tb-exclamation-triangle:before {
  content: "\f071";
}
.tb-chevron-up:before {
  content: "\f077";
}
.tb-chevron-down:before {
  content: "\f078";
}
.tb-chart-bar:before {
  content: "\f080";
}
.tb-phone:before {
  content: "\f095";
}
.tb-arrow-circle-left:before {
  content: "\f0a8";
}
.tb-arrow-circle-right:before {
  content: "\f0a9";
}
.tb-arrow-circle-up:before {
  content: "\f0aa";
}
.tb-arrow-circle-down:before {
  content: "\f0ab";
}
.tb-angle-left:before {
  content: "\f108";
}
.tb-angle-right:before {
  content: "\f109";
}
.tb-angle-up:before {
  content: "\f10a";
}
.tb-angle-down:before {
  content: "\f10b";
}
.tb-quote-left:before {
  content: "\f10d";
}
.tb-quote-right:before {
  content: "\f10e";
}
.tb-reply-all:before {
  content: "\f122";
}
.tb-location-arrow:before {
  content: "\f124";
}
.tb-chevron-circle-left1:before {
  content: "\f13d";
}
.tb-chevron-circle-right1:before {
  content: "\f13e";
}
.tb-chevron-circle-up1:before {
  content: "\f13f";
}
.tb-chevron-circle-down1:before {
  content: "\f140";
}
.tb-ellipsis-h:before {
  content: "\f141";
}
.tb-ellipsis-v:before {
  content: "\f142";
}
.tb-share-square:before {
  content: "\f14d";
}
.tb-dollar-sign:before {
  content: "\f155";
}
.tb-rupee-sign:before {
  content: "\f156";
}
.tb-thumbs-up1:before {
  content: "\f166";
}
.tb-thumbs-down1:before {
  content: "\f16a";
}
.tb-share-alt:before {
  content: "\f1e0";
}
.tb-at:before {
  content: "\f1fa";
}
.tb-mouse-pointer:before {
  content: "\f245";
}
.tb-clone:before {
  content: "\f24d";
}
.tb-pause-circle:before {
  content: "\f28b";
}
.tb-stop-circle:before {
  content: "\f28d";
}
.tb-shopping-bag:before {
  content: "\f290";
}
.tb-shopping-basket:before {
  content: "\f291";
}
.tb-hashtag:before {
  content: "\f292";
}
.tb-user-circle:before {
  content: "\f2bd";
}
.tb-undo-alt:before {
  content: "\f2ea";
}
.tb-sync-alt:before {
  content: "\f2f1";
}
.tb-sign-out-alt:before {
  content: "\f2f5";
}
.tb-sign-in-alt:before {
  content: "\f2f6";
}
.tb-redo-alt:before {
  content: "\f2f9";
}
.tb-pencil-alt:before {
  content: "\f303";
}
.tb-long-arrow-alt-down:before {
  content: "\f309";
}
.tb-long-arrow-alt-left:before {
  content: "\f30a";
}
.tb-long-arrow-alt-right:before {
  content: "\f30b";
}
.tb-long-arrow-alt-up:before {
  content: "\f30c";
}
.tb-expand-arrows-alt:before {
  content: "\f31e";
}
.tb-external-link-alt:before {
  content: "\f35d";
}
.tb-exchange-alt:before {
  content: "\f362";
}
.tb-map-marker-alt1:before {
  content: "\f3c9";
}
.tb-reply:before {
  content: "\f3e5";
}
.tb-user-friends:before {
  content: "\f500";
}
.tb-users-cog:before {
  content: "\f509";
}
.tb-check-double:before {
  content: "\f560";
}
.tb-multiple:before {
  content: "\e901";
}
.tb-video:before {
  content: "\e900";
}
.tb-display:before {
  content: "\e910";
}
.tb-taggbox:before {
  content: "\e920";
}
.tb-play:before {
  content: "\e921";
}
.tb-calendar:before {
  content: "\e922";
}
.tb-toggle:before {
  content: "\e927";
}
.tb-share-in-alt:before {
  content: "\e929";
}
.tb-add-image:before {
  content: "\e92d";
}
.tb-camera:before {
  content: "\e92e";
}
.tb-share-in:before {
  content: "\e92f";
}
.tb-Mail:before {
  content: "\e930";
}
.tb-open-link:before {
  content: "\e931";
}
.tb-bag:before {
  content: "\e932";
}
.tb-workplace:before {
  content: "\e933";
}
.tb-rss:before {
  content: "\e938";
}
.tb-onsite:before {
  content: "\e939";
}
.tb-giphy:before {
  content: "\e93a";
}
.tb-twitter-square:before {
  content: "\f081";
}
.tb-facebook-square:before {
  content: "\f082";
}
.tb-linkedin-square:before {
  content: "\f08c";
}
.tb-twitter:before {
  content: "\f099";
}
.tb-facebook:before {
  content: "\f09a";
}
.tb-github:before {
  content: "\f09b";
}
.tb-globe:before {
  content: "\f0ac";
}
.tb-users:before {
  content: "\f0c0";
}
.tb-link:before {
  content: "\f0c1";
}
.tb-paperclip:before {
  content: "\f0c6";
}
.tb-pinterest:before {
  content: "\f0d2";
}
.tb-pinterest-square:before {
  content: "\f0d3";
}
.tb-google-plus-square:before {
  content: "\f0d4";
}
.tb-google-plus-g:before {
  content: "\f0d5";
}
.tb-caret-down:before {
  content: "\f0d7";
}
.tb-caret-up:before {
  content: "\f0d8";
}
.tb-caret-left:before {
  content: "\f0d9";
}
.tb-caret-right:before {
  content: "\f0da";
}
.tb-sort:before {
  content: "\f0dc";
}
.tb-sort-down:before {
  content: "\f0dd";
}
.tb-sort-up:before {
  content: "\f0de";
}
.tb-linkedin:before {
  content: "\f0e1";
}
.tb-angle-double-left:before {
  content: "\f100";
}
.tb-angle-double-right:before {
  content: "\f101";
}
.tb-angle-double-up:before {
  content: "\f102";
}
.tb-angle-double-down:before {
  content: "\f103";
}
.tb-smile:before {
  content: "\f118";
}
.tb-frown:before {
  content: "\f119";
}
.tb-meh:before {
  content: "\f11a";
}
.tb-chevron-circle-left:before {
  content: "\f137";
}
.tb-chevron-circle-right:before {
  content: "\f138";
}
.tb-chevron-circle-up:before {
  content: "\f139";
}
.tb-chevron-circle-down:before {
  content: "\f13a";
}
.tb-play-circle:before {
  content: "\f144";
}
.tb-thumbs-up:before {
  content: "\f164";
}
.tb-thumbs-down:before {
  content: "\f165";
}
.tb-youtube:before {
  content: "\f167";
}
.tb-xing:before {
  content: "\f168";
}
.tb-instagram:before {
  content: "\f16d";
}
.tb-flickr:before {
  content: "\f16e";
}
.tb-tumblr:before {
  content: "\f173";
}
.tb-tumblr-square:before {
  content: "\f174";
}
.tb-apple:before {
  content: "\f179";
}
.tb-windows:before {
  content: "\f17a";
}
.tb-android:before {
  content: "\f17b";
}
.tb-vk:before {
  content: "\f189";
}
.tb-vimeo-square:before {
  content: "\f194";
}
.tb-slack:before {
  content: "\f198";
}
.tb-google:before {
  content: "\f1a0";
}
.tb-spotify:before {
  content: "\f1bc";
}
.tb-deviantart:before {
  content: "\f1bd";
}
.tb-soundcloud:before {
  content: "\f1be";
}
.tb-vine:before {
  content: "\f1ca";
}
.tb-jsfiddle:before {
  content: "\f1cc";
}
.tb-yelp:before {
  content: "\f1e9";
}
.tb-pinterest-p:before {
  content: "\f231";
}
.tb-whatsapp:before {
  content: "\f232";
}
.tb-opencart:before {
  content: "\f23d";
}
.tb-wikipedia-w:before {
  content: "\f266";
}
.tb-vimeo:before {
  content: "\f27d";
}
.tb-reddit-alien:before {
  content: "\f281";
}
.tb-yoast:before {
  content: "\f2b1";
}
.tb-google-plus:before {
  content: "\f2b3";
}
.tb-buromobelexperte:before {
  content: "\f37f";
}
.tb-facebook-f:before {
  content: "\f39e";
}
.tb-facebook-messenger:before {
  content: "\f39f";
}
.tb-hubspot:before {
  content: "\f3b2";
}
.tb-line:before {
  content: "\f3c0";
}
.tb-map-marker-alt:before {
  content: "\f3c5";
}
.tb-sistrix:before {
  content: "\f3ee";
}
.tb-slack-hash:before {
  content: "\f3ef";
}
.tb-telegram-plane:before {
  content: "\f3fe";
}
.tb-user-alt:before {
  content: "\f406";
}
.tb-whatsapp-square:before {
  content: "\f40c";
}
.tb-airbnb:before {
  content: "\f834";
}
.tb-yammer:before {
  content: "\f840";
}
.tb-instagram-square:before {
  content: "\f955";
}
.tb-tiktok:before {
  content: "\f97b";
}
